module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"type":{"Article":{"limit":9999},"Markets":{"limit":9999},"Campaign":{"limit":9999},"News":{"limit":9999},"MediaItem":{"localFile":{"requestConcurrency":1,"excludeByMimeTypes":[],"maxFileSizeBytes":15728640},"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"Comment":{"exclude":true},"Menu":{"exclude":true},"PostFormat":{"exclude":true},"UserRole":{"exclude":true},"MenuItem":{"exclude":true},"Post":{"exclude":true},"Category":{"exclude":true},"Tag":{"exclude":true},"Page":{"exclude":true},"ContentType":{"exclude":true},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"debug":{"timeBuildSteps":true,"preview":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"production":{"hardCacheMediaFiles":true,"allow404Images":false,"allow401Images":false},"schema":{"timeout":10000000,"requestConcurrency":1,"perPage":10,"previewRequestConcurrency":1,"queryDepth":10,"circularQueryLimit":1,"typePrefix":"Wp"},"html":{"useGatsbyImage":false,"createStaticFiles":false,"imageQuality":70,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"url":"https://h4lwagdvk9bmpthz.miltonmarkets.com/?Gwh2VBwtQFaErGHr3fUkaBv4=true","verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NB4V37X3","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"MILTON MARKETS","short_name":"MILTON MARKETS","lang":"en","description":"Milton Markets is a forex company that allows foreign forex beginners to trade with high leverage. Milton Markets offers account opening bonus and deposit bonus, high execution power with no execution rejection, MT4/MT5 trading without trading fees.","start_url":"/","background_color":"#ffffff","theme_color":"#F7F7F7","display":"minimal-ui","icon":"content/assets/icon-512x512.png","categories":["finance"],"localize":[{"start_url":"/ja/","lang":"ja","description":"Milton Markets(ミルトンマーケッツ)は、海外FX初心者にも安心のハイレバレッジでの取引が可能なFX会社。口座開設ボーナスや入金ボーナスがあり、約定拒否なしの高い約定力、取引手数料なしでMT4/MT5で取引可能。24時間日本語での口座開設サポートあります。"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2085298c19581972c7c154d88be54e03"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
