exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-account-types-js": () => import("./../../../src/pages/account-types.js" /* webpackChunkName: "component---src-pages-account-types-js" */),
  "component---src-pages-cookiepolicy-js": () => import("./../../../src/pages/cookiepolicy.js" /* webpackChunkName: "component---src-pages-cookiepolicy-js" */),
  "component---src-pages-customer-protection-js": () => import("./../../../src/pages/customer-protection.js" /* webpackChunkName: "component---src-pages-customer-protection-js" */),
  "component---src-pages-economic-calender-js": () => import("./../../../src/pages/economic-calender.js" /* webpackChunkName: "component---src-pages-economic-calender-js" */),
  "component---src-pages-execution-conditions-js": () => import("./../../../src/pages/execution-conditions.js" /* webpackChunkName: "component---src-pages-execution-conditions-js" */),
  "component---src-pages-glossary-js": () => import("./../../../src/pages/glossary.js" /* webpackChunkName: "component---src-pages-glossary-js" */),
  "component---src-pages-ib-js": () => import("./../../../src/pages/ib.js" /* webpackChunkName: "component---src-pages-ib-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ja-404-js": () => import("./../../../src/pages/ja/404.js" /* webpackChunkName: "component---src-pages-ja-404-js" */),
  "component---src-pages-ja-about-us-js": () => import("./../../../src/pages/ja/about-us.js" /* webpackChunkName: "component---src-pages-ja-about-us-js" */),
  "component---src-pages-ja-account-types-js": () => import("./../../../src/pages/ja/account-types.js" /* webpackChunkName: "component---src-pages-ja-account-types-js" */),
  "component---src-pages-ja-cookiepolicy-js": () => import("./../../../src/pages/ja/cookiepolicy.js" /* webpackChunkName: "component---src-pages-ja-cookiepolicy-js" */),
  "component---src-pages-ja-customer-protection-js": () => import("./../../../src/pages/ja/customer-protection.js" /* webpackChunkName: "component---src-pages-ja-customer-protection-js" */),
  "component---src-pages-ja-economic-calender-js": () => import("./../../../src/pages/ja/economic-calender.js" /* webpackChunkName: "component---src-pages-ja-economic-calender-js" */),
  "component---src-pages-ja-execution-conditions-js": () => import("./../../../src/pages/ja/execution-conditions.js" /* webpackChunkName: "component---src-pages-ja-execution-conditions-js" */),
  "component---src-pages-ja-glossary-js": () => import("./../../../src/pages/ja/glossary.js" /* webpackChunkName: "component---src-pages-ja-glossary-js" */),
  "component---src-pages-ja-ib-js": () => import("./../../../src/pages/ja/ib.js" /* webpackChunkName: "component---src-pages-ja-ib-js" */),
  "component---src-pages-ja-index-js": () => import("./../../../src/pages/ja/index.js" /* webpackChunkName: "component---src-pages-ja-index-js" */),
  "component---src-pages-ja-legal-documents-js": () => import("./../../../src/pages/ja/legal-documents.js" /* webpackChunkName: "component---src-pages-ja-legal-documents-js" */),
  "component---src-pages-ja-mt-4-desktop-mac-js": () => import("./../../../src/pages/ja/mt4-desktop-mac.js" /* webpackChunkName: "component---src-pages-ja-mt-4-desktop-mac-js" */),
  "component---src-pages-ja-mt-4-desktop-win-js": () => import("./../../../src/pages/ja/mt4-desktop-win.js" /* webpackChunkName: "component---src-pages-ja-mt-4-desktop-win-js" */),
  "component---src-pages-ja-mt-4-mobile-js": () => import("./../../../src/pages/ja/mt4-mobile.js" /* webpackChunkName: "component---src-pages-ja-mt-4-mobile-js" */),
  "component---src-pages-ja-mt-4-web-browser-js": () => import("./../../../src/pages/ja/mt4-web-browser.js" /* webpackChunkName: "component---src-pages-ja-mt-4-web-browser-js" */),
  "component---src-pages-ja-mt-4-webterminal-js": () => import("./../../../src/pages/ja/mt4-webterminal.js" /* webpackChunkName: "component---src-pages-ja-mt-4-webterminal-js" */),
  "component---src-pages-ja-pamm-js": () => import("./../../../src/pages/ja/pamm.js" /* webpackChunkName: "component---src-pages-ja-pamm-js" */),
  "component---src-pages-ja-pamm-leaderboard-js": () => import("./../../../src/pages/ja/pamm-leaderboard.js" /* webpackChunkName: "component---src-pages-ja-pamm-leaderboard-js" */),
  "component---src-pages-ja-pamm-system-js": () => import("./../../../src/pages/ja/pamm-system.js" /* webpackChunkName: "component---src-pages-ja-pamm-system-js" */),
  "component---src-pages-ja-privacypolicy-js": () => import("./../../../src/pages/ja/privacypolicy.js" /* webpackChunkName: "component---src-pages-ja-privacypolicy-js" */),
  "component---src-pages-ja-register-js": () => import("./../../../src/pages/ja/register.js" /* webpackChunkName: "component---src-pages-ja-register-js" */),
  "component---src-pages-ja-regulation-js": () => import("./../../../src/pages/ja/regulation.js" /* webpackChunkName: "component---src-pages-ja-regulation-js" */),
  "component---src-pages-ja-termsofuse-js": () => import("./../../../src/pages/ja/termsofuse.js" /* webpackChunkName: "component---src-pages-ja-termsofuse-js" */),
  "component---src-pages-ja-thanks-js": () => import("./../../../src/pages/ja/thanks.js" /* webpackChunkName: "component---src-pages-ja-thanks-js" */),
  "component---src-pages-ja-thanks-newsletter-js": () => import("./../../../src/pages/ja/thanks-newsletter.js" /* webpackChunkName: "component---src-pages-ja-thanks-newsletter-js" */),
  "component---src-pages-ja-thanks-register-js": () => import("./../../../src/pages/ja/thanks-register.js" /* webpackChunkName: "component---src-pages-ja-thanks-register-js" */),
  "component---src-pages-ja-trading-instruments-js": () => import("./../../../src/pages/ja/trading-Instruments.js" /* webpackChunkName: "component---src-pages-ja-trading-instruments-js" */),
  "component---src-pages-ja-white-label-js": () => import("./../../../src/pages/ja/white-label.js" /* webpackChunkName: "component---src-pages-ja-white-label-js" */),
  "component---src-pages-legal-documents-js": () => import("./../../../src/pages/legal-documents.js" /* webpackChunkName: "component---src-pages-legal-documents-js" */),
  "component---src-pages-mt-4-desktop-mac-js": () => import("./../../../src/pages/mt4-desktop-mac.js" /* webpackChunkName: "component---src-pages-mt-4-desktop-mac-js" */),
  "component---src-pages-mt-4-desktop-win-js": () => import("./../../../src/pages/mt4-desktop-win.js" /* webpackChunkName: "component---src-pages-mt-4-desktop-win-js" */),
  "component---src-pages-mt-4-mobile-js": () => import("./../../../src/pages/mt4-mobile.js" /* webpackChunkName: "component---src-pages-mt-4-mobile-js" */),
  "component---src-pages-mt-4-web-browser-js": () => import("./../../../src/pages/mt4-web-browser.js" /* webpackChunkName: "component---src-pages-mt-4-web-browser-js" */),
  "component---src-pages-mt-4-webterminal-js": () => import("./../../../src/pages/mt4-webterminal.js" /* webpackChunkName: "component---src-pages-mt-4-webterminal-js" */),
  "component---src-pages-pamm-js": () => import("./../../../src/pages/pamm.js" /* webpackChunkName: "component---src-pages-pamm-js" */),
  "component---src-pages-pamm-leaderboard-js": () => import("./../../../src/pages/pamm-leaderboard.js" /* webpackChunkName: "component---src-pages-pamm-leaderboard-js" */),
  "component---src-pages-pamm-system-js": () => import("./../../../src/pages/pamm-system.js" /* webpackChunkName: "component---src-pages-pamm-system-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-regulation-js": () => import("./../../../src/pages/regulation.js" /* webpackChunkName: "component---src-pages-regulation-js" */),
  "component---src-pages-termsofuse-js": () => import("./../../../src/pages/termsofuse.js" /* webpackChunkName: "component---src-pages-termsofuse-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-thanks-newsletter-js": () => import("./../../../src/pages/thanks-newsletter.js" /* webpackChunkName: "component---src-pages-thanks-newsletter-js" */),
  "component---src-pages-thanks-register-js": () => import("./../../../src/pages/thanks-register.js" /* webpackChunkName: "component---src-pages-thanks-register-js" */),
  "component---src-pages-trading-instruments-js": () => import("./../../../src/pages/trading-Instruments.js" /* webpackChunkName: "component---src-pages-trading-instruments-js" */),
  "component---src-pages-white-label-js": () => import("./../../../src/pages/white-label.js" /* webpackChunkName: "component---src-pages-white-label-js" */),
  "component---src-templates-blog-post-archive-cus-js": () => import("./../../../src/templates/blog-post-archive_cus.js" /* webpackChunkName: "component---src-templates-blog-post-archive-cus-js" */),
  "component---src-templates-blog-post-cus-js": () => import("./../../../src/templates/blog-post_cus.js" /* webpackChunkName: "component---src-templates-blog-post-cus-js" */),
  "component---src-templates-campaign-post-archive-js": () => import("./../../../src/templates/campaign-post-archive.js" /* webpackChunkName: "component---src-templates-campaign-post-archive-js" */),
  "component---src-templates-campaign-post-js": () => import("./../../../src/templates/campaign-post.js" /* webpackChunkName: "component---src-templates-campaign-post-js" */),
  "component---src-templates-glossary-post-js": () => import("./../../../src/templates/glossary-post.js" /* webpackChunkName: "component---src-templates-glossary-post-js" */),
  "component---src-templates-markets-post-archive-js": () => import("./../../../src/templates/markets-post-archive.js" /* webpackChunkName: "component---src-templates-markets-post-archive-js" */),
  "component---src-templates-markets-post-js": () => import("./../../../src/templates/markets-post.js" /* webpackChunkName: "component---src-templates-markets-post-js" */),
  "component---src-templates-news-post-archive-js": () => import("./../../../src/templates/news-post-archive.js" /* webpackChunkName: "component---src-templates-news-post-archive-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */)
}

